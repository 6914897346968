/* eslint-disable no-bitwise */
import { useGetAnswerAnalytics } from "aws-summit-api-typescript-react-query-hooks";
import * as d3 from "d3";
import { useRef, useEffect } from "react";
import { Pack } from "./pack";
import { useInterval } from "../../hooks/useInterval";

const AnswerAnalytics = () => {
  const containerRef = useRef<HTMLDivElement>(null);
  const ref = useRef<SVGSVGElement>(null);
  const analytics = useGetAnswerAnalytics();

  useInterval(async () => {
    await analytics.refetch();
  }, 10000);

  useEffect(() => {
    if (analytics.data && ref.current) {
      const rect = containerRef.current!.getBoundingClientRect();
      const width = rect.width;
      const height = rect.height;
      const fetchedData = analytics.data.items.map((item) => ({
        name: item.topLevelBucket,
        children: item.servicesOrTechs.map((tech) => ({
          name: tech.name,
          children: tech.users.map((u) => ({ name: u })),
        })),
      }));
      const packData = {
        name: "Version Up!",
        children: fetchedData,
      };
      Pack(packData, {
        element: ref.current,
        value: (d) => (d.children ? 0 : 1),
        sort: (a, b) => d3.descending(a.value, b.value),
        label: (d) => d.name,
        title: (d) => d.name,
        width,
        height,
        margin: 20,
        padding: 3,
      });
    }
  }, [analytics.data, ref.current]);

  if (analytics.isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div ref={containerRef} className="w-full h-full">
      <svg className="w-full h-full" ref={ref}></svg>
    </div>
  );
};

export default AnswerAnalytics;

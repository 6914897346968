/* tslint:disable */
/* eslint-disable */
/**
 * AWSSummit
 * A sample smithy api
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ResponseAnalyticsItem } from './ResponseAnalyticsItem';
import {
    ResponseAnalyticsItemFromJSON,
    ResponseAnalyticsItemFromJSONTyped,
    ResponseAnalyticsItemToJSON,
} from './ResponseAnalyticsItem';

/**
 * 
 * @export
 * @interface GetAnswerAnalyticsResponseContent
 */
export interface GetAnswerAnalyticsResponseContent {
    /**
     * 
     * @type {Array<ResponseAnalyticsItem>}
     * @memberof GetAnswerAnalyticsResponseContent
     */
    items: Array<ResponseAnalyticsItem>;
}


/**
 * Check if a given object implements the GetAnswerAnalyticsResponseContent interface.
 */
export function instanceOfGetAnswerAnalyticsResponseContent(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "items" in value;

    return isInstance;
}

export function GetAnswerAnalyticsResponseContentFromJSON(json: any): GetAnswerAnalyticsResponseContent {
    return GetAnswerAnalyticsResponseContentFromJSONTyped(json, false);
}

export function GetAnswerAnalyticsResponseContentFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetAnswerAnalyticsResponseContent {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'items': ((json['items'] as Array<any>).map(ResponseAnalyticsItemFromJSON)),
    };
}

export function GetAnswerAnalyticsResponseContentToJSON(value?: GetAnswerAnalyticsResponseContent | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'items': ((value.items as Array<any>).map(ResponseAnalyticsItemToJSON)),
    };
}


import * as d3 from "d3";

type AnalyticsData = {
  radius?: number;
  name: string;
  children?: AnalyticsData[];
};

type HNode = d3.HierarchyNode<AnalyticsData>;

const colorMap = {
  "#D1ECD7": "#95E7A7",
  "#ceeefc": "#9ed7f0",
  "#fad8af": "#f9c77e",
  "#fffdd4": "#FFF177",
  "#ffd1dc": "#f9a3b3",
  "#e3e4ff": "#b3b4f9",
  "#edc8ff": "#d9b4f9",
  "#ffd9d7": "#f9b4b2",
  "#ffcbea": "#f9b4d9",
  "#d9d9d9": "#b4b4b4",
  "#fff8e6": "#f9f4c1",
  "#f4f4f4": "#c1c1c1",
  "#96e7a6": "#7fc96e",
  "#9ed7f0": "#7e9ef9",
  "#f9c77e": "#f9b77e",
  "#f9f9c1": "#f9f4c1",
  "#f9a3b3": "#f97e7e",
  "#b3b4f9": "#7e9ef9",
  "#d9b4f9": "#9e7ef9",
  "#f9b4b2": "#f97e7e",
} as Record<string, string>;

function Pack(
  data: AnalyticsData,
  {
    // data is either tabular (array of objects) or hierarchy (nested objects)
    element,
    value, // given a node d, returns a quantitative value (for area encoding; null for count)
    sort = (a, b) => d3.descending(a.value, b.value), // how to sort nodes prior to layout
    width = 640, // outer width, in pixels
    height = 400, // outer height, in pixels
    margin = 1, // shorthand for margins
    marginTop = margin, // top margin, in pixels
    marginRight = margin, // right margin, in pixels
    marginBottom = margin, // bottom margin, in pixels
    marginLeft = margin, // left margin, in pixels
    padding = 3, // separation between circles
  }: {
    element: SVGSVGElement;
    value?: (d: AnalyticsData) => number;
    sort?: (a: HNode, b: HNode) => number;
    label?: (d: AnalyticsData, node: HNode) => string;
    title?: (d: AnalyticsData, node: HNode) => string;
    color?: (d: AnalyticsData, node: HNode) => string;
    width?: number;
    height?: number;
    margin?: number;
    marginTop?: number;
    marginRight?: number;
    marginBottom?: number;
    marginLeft?: number;
    padding?: number;
    fill?: (d: AnalyticsData, node: HNode) => string;
    fillOpacity?: number;
    stroke?: string;
    strokeWidth?: number;
    strokeOpacity?: number;
  },
) {
  // If id and parentId options are specified, or the path option, use d3.stratify
  // to convert tabular data to a hierarchy; otherwise we assume that the data is
  // specified as an object {children} with nested objects (a.k.a. the “flare.json”
  // format), and use d3.hierarchy.
  const root = d3.hierarchy(data, (d) => d.children);

  // Compute the values of internal nodes by aggregating from the leaves.
  value == null ? root.count() : root.sum((d) => Math.max(0, value(d)));

  // Compute the layout.
  const packedData = d3
    .pack<AnalyticsData>()
    .size([width - marginLeft - marginRight, height - marginTop - marginBottom])
    .padding(padding)(root);

  // Compute labels and titles.
  const descendants = packedData.descendants();

  // Sort the leaves (typically by descending value for a pleasing layout).
  if (sort != null) packedData.sort(sort);

  let reposition = 1; // adjust to prevent clipping
  let scaleBoundingBox = 4; // adjust to prevent clipping
  let angle = 1.75 * Math.PI; // angle of the offset, measured from the right, clockwise in radians
  let distance = 0; // how far the shadow is from object
  let blur = 8; // ammount of Gausian blur
  let shadowColor = "#003152"; //
  let shadowOpacity = 0.6; // how strong the shadow is
  const svg = d3
    .select(element)
    .attr("viewBox", [-marginLeft, -marginTop, width, height])
    .attr("font-family", "Outfit")
    .attr("font-size", 34)
    .attr("font-weight", "bolder")
    .attr("text-anchor", "middle");
  svg.selectAll("*").remove();
  const dropShadow = svg
    .append("filter")
    .attr("id", "dropshadow")
    .attr("x", (1 - scaleBoundingBox) / 2 + reposition * Math.cos(angle))
    .attr("y", (1 - scaleBoundingBox) / 2 - reposition * Math.sin(angle))
    .attr("width", scaleBoundingBox)
    .attr("height", scaleBoundingBox)
    .attr("filterUnits", "objectBoundingBox"); // userSpaceOnUse or objectBoundingBox
  dropShadow
    .append("feGaussianBlur")
    .attr("in", "SourceAlpha")
    .attr("stdDeviation", blur)
    .attr("result", "blur");
  dropShadow
    .append("feOffset")
    .attr("in", "blur")
    .attr("dx", distance * Math.cos(angle))
    .attr("dy", distance * -Math.sin(angle))
    .attr("result", "offsetBlur");
  dropShadow
    .append("feFlood")
    .attr("in", "offsetBlur")
    .attr("flood-color", shadowColor)
    .attr("flood-opacity", shadowOpacity)
    .attr("result", "offsetColor");
  dropShadow
    .append("feComposite")
    .attr("in", "offsetColor")
    .attr("in2", "offsetBlur")
    .attr("operator", "in")
    .attr("result", "offsetBlur");

  var feMerge = dropShadow.append("feMerge");
  feMerge.append("feMergeNode").attr("in", "offsetBlur");
  feMerge.append("feMergeNode").attr("in", "SourceGraphic");

  // const paddingPerDepth = 5; // Adjust this value as needed
  const node = svg
    .selectAll("g")
    .data(descendants)
    .join("g")
    .attr("transform", (d) => `translate(${d.x},${d.y})`);
  const leaves = node.filter((d) => !d.children);
  leaves.append("title").text((d) => d.data.name);
  const categories = node.filter((d) => d.depth === 1);

  const keys = categories
    .data()
    .filter((c) => c.depth === 1)
    .map((d) => d.data.name);
  const color = d3.scaleOrdinal().domain(keys).range(Object.keys(colorMap));
  const addText = (
    selection: d3.Selection<
      d3.BaseType | SVGElement,
      d3.HierarchyCircularNode<AnalyticsData>,
      SVGSVGElement,
      unknown
    >,
    style: {
      yCoefficient: number;
      size: number;
      weight?: string;
      zIndex: number;
    },
  ) => {
    return selection.each((d) => {
      if ((d.children?.length ?? 0) === 0) return;
      svg
        .append("text")
        .text(d.data.name)
        .attr("x", d.x)
        .attr("y", d.y + style.yCoefficient)
        .attr("text-anchor", "middle")
        .attr("allingment-baseline", "middle")
        .call((n) => {
          n.each(function () {
            let text = d3.select(this),
              words = text.text().split(/\s+/).reverse(),
              word: string | undefined,
              line: string[] = [],
              lineNumber = 0,
              lineHeight = 1.1, // ems
              x = text.attr("x"),
              y = text.attr("y"),
              dy = 0, //parseFloat(text.attr("dy")),
              tspan = text
                .text(null)
                .append("tspan")
                .attr("x", x)
                .attr("y", y)
                .attr("dy", dy + "em");
            while ((word = words.pop())) {
              line.push(word);
              tspan.text(line.join(" "));
              if (tspan.node()!.getComputedTextLength() > d.r) {
                line.pop();
                tspan.text(line.join(" "));
                line = [word];
                tspan = text
                  .append("tspan")
                  .attr("x", x)
                  .attr("y", y)
                  .attr("dy", ++lineNumber * lineHeight + dy + "em")
                  .text(word);
              }
            }
            let tspans = text.selectAll("tspan");
            let textBlockHeight = tspans.size() * lineHeight;
            let textBlockShift = -(textBlockHeight / 2) * lineHeight;

            tspans.attr("dy", (_, ix) => {
              const dy1 = (ix + 1) * lineHeight + textBlockShift;
              const dy2 = ix * lineHeight + textBlockShift;
              return (dy1 + dy2) / 2 + "em";
            });
          });
        });
    });
  };

  node
    .append("circle")
    .attr("z-index", (d) => d.depth)
    .attr("fill", (d) =>
      d.depth === 0
        ? "#fbeaa9"
        : d.depth === 1
          ? (color(d.data.name) as string)
          : colorMap[color(d.parent!.data.name) as string],
    )
    .attr("stroke", () => "black") // stroke color for all circles
    .attr("stroke-width", 0)
    .attr("stroke-opacity", (d) => (d.depth === 2 ? 0.2 : 1))
    .attr("filter", (d) => (d.depth === 0 ? "url(#dropshadow)" : ""))
    .attr("r", (d) => {
      let circlePadding = d.depth === 3 ? 0 : 1;
      circlePadding =
        d.parent?.children?.length === 1 ? circlePadding * 0.75 : circlePadding;
      const radius = Math.max(0, d.r * circlePadding); // Ensure radius is not negative
      d.data.radius = radius;
      return radius;
    });

  addText(categories, { size: 14, yCoefficient: 0, zIndex: 100 });
}

export { Pack };

/*! Copyright [Amazon.com](http://amazon.com/), Inc. or its affiliates. All Rights Reserved.
SPDX-License-Identifier: Apache-2.0 */
import { DefaultApiClientProvider as AWSSummitApiClientProvider } from "aws-summit-api-typescript-react-query-hooks";
import React, { PropsWithChildren } from "react";
import { useAWSSummitApiClient } from "../../hooks/useTypeSafeApiClient";

/**
 * Sets up the Type Safe Api clients.
 */
const TypeSafeApiClientProvider: React.FC<
  PropsWithChildren<{ requireAuth: boolean }>
> = ({ children, requireAuth }) => {
  const AWSSummitClient = useAWSSummitApiClient({ requireAuth });

  return (
    <AWSSummitApiClientProvider apiClient={AWSSummitClient!}>
      {children}
    </AWSSummitApiClientProvider>
  );
};

export default TypeSafeApiClientProvider;

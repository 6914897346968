import React, { useEffect, useState } from "react";
import "./index.css";

type ChironProps = {
  addChironToQueue: (addFunction: (message: string) => void) => void;
  defaultText: string;
};

const Chiron: React.FC<ChironProps> = ({ addChironToQueue, defaultText }) => {
  const [chironQueue, setChironQueue] = useState<string[]>([]);
  const [currentChiron, setCurrentChiron] = useState<string>(defaultText);
  const [isScrolling, setIsScrolling] = useState<boolean>(false);

  const setNextChiron = () => {
    if (chironQueue.length > 0) {
      console.log(`Setting next chiron`);
      setCurrentChiron(chironQueue[0]);
      setChironQueue((prevQueue) => prevQueue.slice(1));
      setIsScrolling(true);
    } else {
      console.log(`No more chiron messages`);
      setCurrentChiron(defaultText);
      setIsScrolling(false);
    }
  };

  useEffect(() => {
    const addMessageToQueue = (message: string) => {
      console.log(`Adding message to queue: ${message}`);
      setChironQueue((prevQueue) => [...prevQueue, message]);
    };
    addChironToQueue(addMessageToQueue);
  }, [addChironToQueue]);

  useEffect(() => {
    if (!isScrolling) {
      setNextChiron();
    }
  }, [chironQueue]);
  return (
    <div className="flex justify-center align-middle h-full">
      <div
        className={`${isScrolling ? "marquee scrolling" : ""} my-auto`}
        onAnimationIteration={() => {
          console.log(`Animation iteration`);
          setNextChiron();
        }}
      >
        {currentChiron}
      </div>
    </div>
  );
};

export default Chiron;

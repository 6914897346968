/*! Copyright [Amazon.com](http://amazon.com/), Inc. or its affiliates. All Rights Reserved.
SPDX-License-Identifier: Apache-2.0 */
import "./index.css";
import { NorthStarThemeProvider } from "@aws-northstar/ui";
import React from "react";
import { createRoot } from "react-dom/client";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
// import Auth from "./components/Auth";
import Auth from "./components/Auth";
import RuntimeContextProvider from "./components/RuntimeContext";
import { SidebarProvider } from "./components/SidebarProvider";
import TypeSafeApiClientProvider from "./components/TypeSafeApiClientProvider";
import Portal from "./layouts/Portal";
import Public from "./layouts/Public";
import PortalHome from "./pages/Portal/Home";
import PublicHome from "./pages/Public/Home";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Public />,
    children: [
      {
        path: "/",
        element: (
          <TypeSafeApiClientProvider requireAuth={false}>
            <PublicHome />
          </TypeSafeApiClientProvider>
        ),
      },
    ],
  },
  {
    path: "/portal",
    element: (
      <Auth>
        <TypeSafeApiClientProvider requireAuth={true}>
          <Portal />
        </TypeSafeApiClientProvider>
      </Auth>
    ),
    children: [
      {
        path: "/portal",
        element: <PortalHome />,
      },
    ],
  },
]);

createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <NorthStarThemeProvider>
      <RuntimeContextProvider>
        {/* <Auth> */}
        <SidebarProvider>
          <RouterProvider router={router} />
        </SidebarProvider>
        {/* </Auth> */}
      </RuntimeContextProvider>
    </NorthStarThemeProvider>
  </React.StrictMode>,
);

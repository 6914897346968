/* tslint:disable */
/* eslint-disable */
/**
 * AWSSummit
 * A sample smithy api
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  BadRequestErrorResponseContent,
  GetAnswerAnalyticsResponseContent,
  InternalFailureErrorResponseContent,
  NotAuthorizedErrorResponseContent,
  PutUserResponseBody,
  PutUserResponseResponseContent,
} from '../models';
import {
    BadRequestErrorResponseContentFromJSON,
    BadRequestErrorResponseContentToJSON,
    GetAnswerAnalyticsResponseContentFromJSON,
    GetAnswerAnalyticsResponseContentToJSON,
    InternalFailureErrorResponseContentFromJSON,
    InternalFailureErrorResponseContentToJSON,
    NotAuthorizedErrorResponseContentFromJSON,
    NotAuthorizedErrorResponseContentToJSON,
    PutUserResponseBodyFromJSON,
    PutUserResponseBodyToJSON,
    PutUserResponseResponseContentFromJSON,
    PutUserResponseResponseContentToJSON,
} from '../models';

export interface PutUserResponseRequest {
    putUserResponseBody: PutUserResponseBody;
}

/**
 * 
 */
export class DefaultApi extends runtime.BaseAPI {

    /**
     */
    async getAnswerAnalyticsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetAnswerAnalyticsResponseContent>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/responses/analytics`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetAnswerAnalyticsResponseContentFromJSON(jsonValue));
    }

    /**
     */
    async getAnswerAnalytics(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetAnswerAnalyticsResponseContent> {
        const response = await this.getAnswerAnalyticsRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async putUserResponseRaw(requestParameters: PutUserResponseRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PutUserResponseResponseContent>> {
        if (requestParameters.putUserResponseBody === null || requestParameters.putUserResponseBody === undefined) {
            throw new runtime.RequiredError('putUserResponseBody','Required parameter requestParameters.putUserResponseBody was null or undefined when calling putUserResponse.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/responses`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: PutUserResponseBodyToJSON(requestParameters.putUserResponseBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PutUserResponseResponseContentFromJSON(jsonValue));
    }

    /**
     */
    async putUserResponse(requestParameters: PutUserResponseRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PutUserResponseResponseContent> {
        const response = await this.putUserResponseRaw(requestParameters, initOverrides);
        return await response.value();
    }

}

export const QUESTION = "What new techs or AWS Services will help you version up this year?";
export const BUCKETS = [
  "AI and Machine Learning",
  "AWS Services",
  "Databases",
  "Programming and Development Tools",
  "Data Engineering and Analytics",
  "Open Source Technologies",
  "Security and Compliance",
  "Networking and Content Delivery",
  "Customer Engagement",
  "Business Applications",
  "Database Services",
  "Storage Services",
  "Compute Services",
  "Internet of Things",
  "Blockchain",
  "Quantum Technologies",
  "Migration and Transfer Services",
  "DevSecOps",
  "Other Cloud",
];

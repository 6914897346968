/* tslint:disable */
/* eslint-disable */
/**
 * AWSSummit
 * A sample smithy api
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PutUserResponseBody
 */
export interface PutUserResponseBody {
    /**
     * 
     * @type {string}
     * @memberof PutUserResponseBody
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof PutUserResponseBody
     */
    alias?: string;
    /**
     * 
     * @type {string}
     * @memberof PutUserResponseBody
     */
    responseToQuestion: string;
    /**
     * 
     * @type {number}
     * @memberof PutUserResponseBody
     */
    howManyPeopleGuess: number;
}


/**
 * Check if a given object implements the PutUserResponseBody interface.
 */
export function instanceOfPutUserResponseBody(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "email" in value;
    isInstance = isInstance && "responseToQuestion" in value;
    isInstance = isInstance && "howManyPeopleGuess" in value;

    return isInstance;
}

export function PutUserResponseBodyFromJSON(json: any): PutUserResponseBody {
    return PutUserResponseBodyFromJSONTyped(json, false);
}

export function PutUserResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): PutUserResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'email': json['email'],
        'alias': !exists(json, 'alias') ? undefined : json['alias'],
        'responseToQuestion': json['responseToQuestion'],
        'howManyPeopleGuess': json['howManyPeopleGuess'],
    };
}

export function PutUserResponseBodyToJSON(value?: PutUserResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'email': value.email,
        'alias': value.alias,
        'responseToQuestion': value.responseToQuestion,
        'howManyPeopleGuess': value.howManyPeopleGuess,
    };
}


// Import models
import type {
  BadRequestErrorResponseContent,
  GetAnswerAnalyticsResponseContent,
  InternalFailureErrorResponseContent,
  NotAuthorizedErrorResponseContent,
  PutUserResponseBody,
  PutUserResponseResponseContent,
  
} from '../models';

// Import request parameter interfaces
import {
    
    PutUserResponseRequest,
    
    
} from '..';

import { ResponseError } from '../runtime';
import { DefaultApi } from './DefaultApi';
import { DefaultApiDefaultContext } from "./DefaultApiClientProvider";

import {
    useQuery,
    UseQueryResult,
    UseQueryOptions,
    useInfiniteQuery,
    UseInfiniteQueryResult,
    UseInfiniteQueryOptions,
    useMutation,
    UseMutationOptions,
    UseMutationResult
} from "@tanstack/react-query";
import { createContext, useContext } from "react";

/**
 * Context for the API client used by the hooks.
 */
export const DefaultApiClientContext = createContext<DefaultApi | undefined>(undefined);

const NO_API_ERROR = new Error(`DefaultApi client missing. Please ensure you have instantiated the DefaultApiClientProvider with a client instance.`);


/**
 * useQuery hook for the GetAnswerAnalytics operation
 */
export const useGetAnswerAnalytics = <TError = ResponseError>(

    options?: Omit<UseQueryOptions<GetAnswerAnalyticsResponseContent, TError>, 'queryKey' | 'queryFn'>
): UseQueryResult<GetAnswerAnalyticsResponseContent, TError> => {
    const api = useContext(DefaultApiClientContext);
    if (!api) {
        throw NO_API_ERROR;
    }
    return useQuery(["getAnswerAnalytics"], () => api.getAnswerAnalytics(), {
        context: DefaultApiDefaultContext,
        ...options,
    });
};


/**
 * useMutation hook for the PutUserResponse operation
 */
export const usePutUserResponse = <TError = ResponseError>(
    options?: Omit<UseMutationOptions<PutUserResponseResponseContent, TError, PutUserResponseRequest>, 'mutationFn'>
): UseMutationResult<PutUserResponseResponseContent, TError, PutUserResponseRequest> => {
    const api = useContext(DefaultApiClientContext);
    if (!api) {
        throw NO_API_ERROR;
    }
    return useMutation((params: PutUserResponseRequest) => api.putUserResponse(params), {
        context: DefaultApiDefaultContext,
        ...options,
    });
};


import React, { createContext, useState, useContext, useEffect } from "react";

interface SidebarContextProps {
  isMobile: boolean;
  showSidebar: boolean;
  toggleSidebar: (open: boolean) => void;
  contextItems?: React.ReactNode;
  setContextItems: (items: React.ReactNode) => void;
  clearContextItems: () => void;
}

const SidebarContext = createContext<SidebarContextProps | undefined>(
  undefined,
);

export const SidebarProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [isMobile, setMobile] = useState(document.body.clientWidth <= 498);
  const [sidebarStatus, setSidebarStatus] = useState<"init" | "open" | "close">(
    "init",
  );
  const showSidebar =
    sidebarStatus === "open" || (!isMobile && sidebarStatus === "init");
  const [contextItems, setContextItems] = useState<React.ReactNode>();
  const clearContextItems = () => setContextItems([]);

  const toggleSidebar = (open: boolean) => {
    setSidebarStatus(open ? "open" : "close");
  };

  useEffect(() => {
    const checkMobile = (event: MediaQueryListEvent) => {
      setMobile(event.matches);
    };

    const mq = window.matchMedia("(max-width: 498px)");
    mq.addEventListener("change", checkMobile);

    // Initial check
    setMobile(mq.matches);

    return () => mq.removeEventListener("change", checkMobile);
  }, []);

  return (
    <SidebarContext.Provider
      value={{
        isMobile,
        showSidebar,
        toggleSidebar,
        contextItems,
        setContextItems,
        clearContextItems,
      }}
    >
      {children}
    </SidebarContext.Provider>
  );
};

export const useSidebar = () => {
  const context = useContext(SidebarContext);
  if (context === undefined) {
    throw new Error("useSidebar must be used within a SidebarProvider");
  }
  return context;
};

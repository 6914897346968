import "./index.css";
import { Outlet } from "react-router-dom";

function App() {
  return (
    <div className="flex h-screen bg-gray-100">
      <div className="flex-1 flex flex-col">
        <header className="relative text-center p-4 border-b bg-gold">
          <svg
            width="85.33333333333333"
            height="38"
            className="absolute left-4 top-1/2 transform -translate-y-1/2 transition-all duration-300 fill-black"
            viewBox="0 0 256 114"
            aria-hidden="true"
          >
            <path d="M255.12 56.69C255.12 25.38 229.74 0 198.43 0C167.12 0 141.74 25.38 141.74 56.69H198.43L141.74 113.38H255.13V85.03H226.78L255.13 56.68L255.12 56.69Z"></path>
            <path d="M93.54 28.35L74.41 0H0L76.54 113.39L153.07 0H112.68L93.54 28.35Z"></path>
          </svg>
          <div className="flex text-xl justify-center items-center h-full">
            Guessing Comp!
          </div>
        </header>

        <main className="flex-1 overflow-x-hidden overflow-y-auto bg-white">
          <div className="container mx-auto px-4 py-4">
            <Outlet />
          </div>
        </main>
      </div>
    </div>
  );
}

export default App;

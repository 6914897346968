import useSigV4Client from "@aws-northstar/ui/components/CognitoAuth/hooks/useSigv4Client";
import {
  DefaultApi as AWSSummitApi,
  Configuration as AWSSummitApiConfiguration,
} from "aws-summit-api-typescript-react-query-hooks";
import { useContext, useMemo } from "react";
import { RuntimeConfigContext } from "../components/RuntimeContext";

const stripLastSlash = (url: string) =>
  url.endsWith("/") ? url.slice(0, -1) : url;
export const useAWSSummitApiClient = ({
  requireAuth,
}: {
  requireAuth: boolean;
}) => {
  const client = requireAuth ? useSigV4Client() : fetch;
  const runtimeContext = useContext(RuntimeConfigContext);

  return useMemo(() => {
    return runtimeContext?.typeSafeApis?.AWSSummit
      ? new AWSSummitApi(
          new AWSSummitApiConfiguration({
            basePath: stripLastSlash(runtimeContext.typeSafeApis.AWSSummit),
            fetchApi: client,
          }),
        )
      : undefined;
  }, [client, runtimeContext?.typeSafeApis?.AWSSummit]);
};

import { useCognitoAuthContext } from "@aws-northstar/ui";
import { useEffect, useState } from "react";
import useWebSocket from "react-use-websocket";

export const useCognitoWebhook = (socketUrl: string) => {
  const [token, setToken] = useState<string | null>(null);
  const { getAuthenticatedUserSession } = useCognitoAuthContext();

  useEffect(() => {
    getAuthenticatedUserSession()
      .then((r) => r?.getIdToken().getJwtToken())
      .then((t) => {
        setToken(t!);
      })
      .catch((e) => {
        console.error(e);
      });
  }, [getAuthenticatedUserSession]);

  const {
    readyState,
    sendJsonMessage,
    sendMessage,
    lastJsonMessage,
    lastMessage,
    getWebSocket,
  } = useWebSocket(token ? `${socketUrl}?idToken=${token}` : null, {
    shouldReconnect: () => true, // Will attempt to reconnect on all close events
  });

  return {
    readyState,
    sendJsonMessage,
    sendMessage,
    lastJsonMessage,
    lastMessage,
    getWebSocket,
  };
};
